import { defineStore } from "pinia";
// import { useStorage } from "@vueuse/core";

export const globalStore = defineStore("globalStore", {
	state: () => ({
		currentUser: null,
		// currentUser: useStorage("currentUser", {}),
	}),
	// getters: {
	//   double: state => state.count * 2,
	// },
	actions: {
		setUser(user) {
			localStorage.setItem("g-user", JSON.stringify(user));
			this.currentUser = user;
		},
		logout() {
			this.deleteUser();
		},
		getUser() {
			if (localStorage.getItem("g-user")) {
				this.currentUser = JSON.parse(localStorage.getItem("g-user"));
			}
			if (!this.currentUser) {
				this.currentUser = null;
			}
			return this.currentUser;
		},
		deleteUser() {
			localStorage.removeItem("g-user");
			this.currentUser = null;

			// Refresh the page
			window.location.reload();

			return;
		},
	},
});
